import { InputLabel, TextField, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { Control, Controller, RefCallBack, RegisterOptions } from 'react-hook-form';
import { StrapiInputField } from '@/lib/strapi/strapi.types';
import { useStyles } from '@/lib/styles/styles.hooks';
import { AppInputSimpleStyles } from './AppInputSimple.styles';

interface AppInputProps {
  /* input id */
  id?: string;
  /** Strapi input data */
  inputFieldData: StrapiInputField;
  /** name attribute of the `input` element */
  name?: string;
  /** control */
  control: Control<any, any>;
  /** validation rules */
  rules?: RegisterOptions;
  /** error */
  error?: boolean;
  /** required */
  required?: boolean;
  /** disabled */
  disabled?: boolean;
  /** helperText */
  helperText?: string;
  /** ref passed to the input element */
  inputRef?: RefCallBack;
  /** style */
  sx?: SxProps<Theme>;
}

const AppInputSimple: FC<AppInputProps> = ({
  inputFieldData,
  id,
  control,
  rules,
  name,
  error,
  inputRef,
  disabled = inputFieldData?.disabled,
  helperText = inputFieldData?.helperText,
  sx,
}) => {
  const styles = useStyles(AppInputSimpleStyles, {}, sx);

  return (
    <Box sx={styles.root} className="AppInputSimple-root">
      <InputLabel disabled={disabled}>{inputFieldData?.label}</InputLabel>

      <Controller
        name={(name || inputFieldData?.name) as string}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            id={id}
            placeholder={inputFieldData?.placeholder}
            error={error}
            variant={inputFieldData?.variant}
            disabled={disabled}
            helperText={helperText}
            type={inputFieldData?.type}
            defaultValue={inputFieldData?.defaultValue}
            inputRef={inputRef}
            className={'AppInput-TextField'}
            sx={styles.textField}
          />
        )}
      />
    </Box>
  );
};

export default AppInputSimple;
