const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    transition: 'all .3s ease',
  },
  topLabel: {
    display: 'block',
    marginBottom: [2],
  },
  topLabelDisabled: {
    display: 'block',
    marginBottom: [2],
    color: 'contentPrimary.disabled',
  },
  textFieldLabel: {
    display: 'flex',
    alignItems: 'center',
  },
};

export { styles as AppInputSimpleStyles };
