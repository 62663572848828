import AppLink from '@/components/atoms/AppLink/AppLink';
import IconContainer from '@/components/atoms/IconContainer/IconContainer';
import { StrapiButton, StrapiHeadingGroup, StrapiLink } from '@/lib/strapi/strapi.types';
import { useStyles } from '@/lib/styles/styles.hooks';
import { Box, BoxProps, Button, SxProps, Theme, Typography } from '@mui/material';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import Loading from '../Loading/Loading';
import { HeadingGroupStyles } from './HeadingGroup.styles';

interface HeadingGroupProps extends BoxProps {
  /** strapi heading group data */
  headingGroupData?: StrapiHeadingGroup;
  /** subhead Icon */
  subheadIcon?: JSX.Element;
  /** style */
  sx?: SxProps<Theme>;
  overrideSXStyles?: boolean;
}

const HeadingGroup: FC<HeadingGroupProps> = ({
  headingGroupData,
  subheadIcon,
  sx,
  overrideSXStyles = false,
  ...rest
}) => {
  const [btnArray, setBtnArray] = useState<StrapiButton[]>([]);
  const [linkArray, setLinkArray] = useState<StrapiLink[]>([]);

  const styles = useStyles(HeadingGroupStyles, {}, sx, overrideSXStyles);

  useEffect(() => {
    if (!headingGroupData) return;
    if (headingGroupData?.buttonData) {
      const _btnArray = Array.isArray(headingGroupData?.buttonData)
        ? headingGroupData.buttonData
        : [headingGroupData?.buttonData];
      setBtnArray(_btnArray);
    }
    if (headingGroupData?.linkData) {
      const _linkArray = Array.isArray(headingGroupData?.linkData)
        ? headingGroupData.linkData
        : [headingGroupData?.linkData];
      setLinkArray(_linkArray);
    }
  }, [headingGroupData]);

  return (
    <Box sx={styles.root} className="AppLink-root" {...rest}>
      <Box>
        <Typography
          sx={headingGroupData?.titleVariant === 'h1' ? styles.title : styles.titleSmall}
          variant={headingGroupData?.titleVariant}
        >
          {headingGroupData?.title}
        </Typography>
        {headingGroupData?.subhead && (
          <Typography sx={styles.subheadContainer} variant="h4">
            {headingGroupData?.subhead}
          </Typography>
        )}
        {subheadIcon && <IconContainer sx={styles.subheadContainer}>{subheadIcon}</IconContainer>}
        <Box sx={styles.stats}>
          {headingGroupData?.statsTitle && (
            <Typography variant="h6" sx={headingGroupData?.loadingData?.isLoading ? null : styles.statsTitle}>
              {headingGroupData.statsTitle}
            </Typography>
          )}
          {headingGroupData?.loadingData?.isLoading && (
            <Loading
              progress={headingGroupData?.loadingData.progress}
              progressWidth={headingGroupData?.loadingData.progressWidth}
            />
          )}
        </Box>
        {headingGroupData?.body ? (
          <Typography sx={styles.body} variant="body">
            {headingGroupData?.body}
          </Typography>
        ) : null}

        {linkArray.length > 0 && headingGroupData?.linkData && (
          <Box sx={styles.linkContainer} className="AppLink-linkContainer">
            {linkArray?.map((link) => (
              <AppLink
                key={`HeadingGroupLink-${link.id}`}
                sx={styles.link}
                label={link.label}
                href={link.href}
                buttonProps={link.buttonProps}
                variant={link.variant}
                hasArrowIcon={link.hasArrowIcon}
                buttonColor={link.buttonColor}
                buttonVariant={link.buttonVariant}
                color={link.color}
              />
            ))}
          </Box>
        )}
        {btnArray.length > 0 && headingGroupData?.buttonData ? (
          <Box sx={styles.buttonContainer}>
            {btnArray?.map((button, id) => (
              <Link href={`${button.href}`} passHref key={`${id}`}>
                <Button key={`HeadingGroupButton-${button.id}`} variant={button.variant} color={button.color}>
                  {button.label}
                </Button>
              </Link>
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
export default HeadingGroup;
