import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, ReactNode } from 'react';
import { PageLayoutStyles } from './PageLayout.styles';

interface PageLayoutProps {
  children?: ReactNode;
  /** styles */
  sx?: SxProps<Theme>;
}

const PageLayout: FC<PageLayoutProps> = ({ children, sx }) => {
  const styles = useStyles(PageLayoutStyles, {}, sx);

  return (
    <Box sx={styles.root} className="PageLayout-root">
      <Box sx={styles.mainContent} className="PageLayout-mainContent" id={'PageLayout-mainContent'}>
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
