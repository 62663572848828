import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

//
// add tablet & mobile
//

const styles: ComponentStylesWithFns = {
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    margin: ['0px 0px 20px 0px'],
  },
  titleSmall: {
    margin: ['0px 0px 12px 0px'],
  },
  subheadContainer: {
    display: 'block',
    marginBottom: '20px',
  },
  linkContainer: {
    display: 'flex',
    gap: ['20px'],
    marginTop: [4],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: ['column', 'row', 'row', 'row'],
    marginTop: [4],
    gap: [3, 2, 2, 2],
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsTitle: {
    marginBottom: ['12px'],
    display: 'inline-block',
  },
  body: {
    whiteSpace: 'break-spaces',
  },
};

export { styles as HeadingGroupStyles };
