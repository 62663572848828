import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingTop: [0],
    paddingBottom: [3, 3, 4, 7],
    paddingX: [3, 3, 4, 4],
    boxSizing: 'border-box',
  },
  mainContent: {
    width: '100%',
    height: '100%',
    maxWidth: ['342px', '568px', '852px', '1152px'],
  },
};

export { styles as PageLayoutStyles };
