import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { LoadingStyles } from './Loading.styles';

export interface LoadingProps {
  progress?: 'neutral' | 'sufficient' | 'warning' | 'critical';
  alignment?: 'right' | 'left';
  progressWidth?: number;
  sx?: SxProps<Theme>;
  useColors?: boolean;
}

const Loading: FC<LoadingProps> = ({
  progress = 'neutral',
  progressWidth = 15,
  alignment = 'left',
  sx,
  useColors = false,
}) => {
  const styles = useStyles(LoadingStyles, { progress, progressWidth, alignment, useColors }, sx);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.progress}></Box>
    </Box>
  );
};

export default Loading;
