import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { FormStyles } from '../Form.styles';

const styles: ComponentStylesWithFns = {
  ...FormStyles,
  root: {
    justifyContent: 'center',
    display: ['grid', 'flex'],
    gridTemplateColumns: ['repeat(1, 1fr)', '1fr 1fr'],
    gap: 4,
    alignContent: 'center',
    marginY: '24px',
    '.MuiFormLabel-root': {
      display: 'none',
    }
  },
  mailInput: {
    position: 'relative',
    'svg': {
      position: 'absolute',
      zIndex: '9999',
      left: '10px',
      top: '15px',
      height: '20px',
    },
    'input': {
      paddingLeft: '20px!important',

    },
    '.MuiFormControl-root': {
      marginBottom: ['0'],
      width: '330px',
    }
  },
  submitButton: {
    width: ['100%', '210px'],
    height: '50px',
    color: '#0F172A!important',
    backgroundColor: '#A3E635!important',
    'svg': {
      height: '20px',
      paddingLeft: '10px',
    },
  }
  }
;

export { styles as CreateEarlyRegisterFromStyles };
