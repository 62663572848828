import { ComponentStylesWithFns } from '../styles.types';
import { PageStyles } from './page.styles';

const styles: ComponentStylesWithFns = {
  ...PageStyles,
  earlyRegContainer: {
    backgroundImage: 'linear-gradient(180deg, #4642AF 0%, rgba(42, 15, 25, 0.00) 50%)',
    backgroundRepeat: 'no-repeat',
  },
  earlyRegTextForm: {
    marginTop: '115px',
    textAlign: 'center',
    '.MuiBox-root': {
      'h4': {
        color: 'warning.main',
      },
    },
  },
  earlyRegForm: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },

  ladsLetterText: {
    marginTop: ['70px'],
    display: 'grid',
    gap: '24px',
    gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)'],
  },
  arrowLetter: {
    'svg': {
      marginTop: '15px',
      paddingLeft: '10px',
      width: ['250px', '330px', '330px', '330px'],
    },
  },
  letter: {
    border: '2px solid #22D3EE',
    borderRadius: '50%',
    padding: '3px 10px',
    color: '#22D3EE',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  textLad: {
    marginTop: ['15px'],
  },
  bottomPart: {
    backgroundImage: 'url(/images/scroll_interactive_imagery_02.png)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    '.PageLayout-root': {
      paddingBottom: 0,
    },
  },
  textWithLink:  {
    textAlign: 'center',
    marginTop: '150px',
    marginBottom: '200px',
    'svg': {
      background: '#5865F2',
      padding: '20px',
      marginBottom: '64px',
      borderRadius: '15px',
    },
  },
  textLink: {
    display: 'inline-block',
  },
  inline: {
    '.MuiTypography-root': {
        color: 'core.main',
    },
    display: 'inline-block',
    color: '#A3E635!important',
    textDecoration: 'underline',
  },
};

export { styles as LandingPageStyles };
