import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface LoadingStyleProps extends StyleStates {
  progress: 'neutral' | 'sufficient' | 'warning' | 'critical';
  alignment: 'right' | 'left';
  progressWidth?: number;
  useColors: boolean;
}

const styles: ComponentStylesWithFns<LoadingStyleProps> = {
  root: {
    height: '2px',
    width: '100%',
    display: 'flex',
    position: 'relative',
    margin: '12px 0px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  progress: ({ progress, progressWidth, alignment, useColors }) => [
    {
      position: 'absolute',
      height: '2px',
      width: () => `${progressWidth}%`,
    },
    alignment === 'right' && {
      right: 0,
    },
    progress === 'neutral' &&
      !useColors && {
        backgroundColor: 'contentPrimary.primary',
      },
    progress === 'neutral' &&
      useColors && {
        backgroundColor: () =>
          progressWidth
            ? progressWidth <= 33
              ? `error.main`
              : progressWidth < 66
              ? `warning.main`
              : `success.main`
            : 'contentPrimary.primary',
      },
    progress === 'sufficient' && {
      backgroundColor: 'success.main',
    },
    progress === 'warning' && {
      backgroundColor: 'warning.main',
    },
    progress === 'critical' && {
      backgroundColor: 'error.main',
    },
  ],
};

export { styles as LoadingStyles };
