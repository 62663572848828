import AppInputSimple from '@/components/molecules/AppInputSimple/AppInputSimple';
import { useStyles } from '@/lib/styles/styles.hooks';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { MailIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { FormProvider, useForm } from 'react-hook-form';
import { FormProps } from '../form.types';
import { CreateEarlyRegisterFromStyles } from '@/components/blocks/Forms/CreateEarlyRegister/CreateEarlyRegister.styles';


interface LoginFormProps extends FormProps<CreateTeamFormData> {
  /** labels */
  labels?: {
    nameLabel?: string;
    tagLabel?: string;
    submitLabel?: string;
  };
}

export interface CreateTeamFormData {
  name: string;
  tag: string;
}

const CreateEarlyRegister: FC<LoginFormProps> = ({ onSubmit, onSubmitError, fields, formButton, sx }) => {
  const styles = useStyles(CreateEarlyRegisterFromStyles, {}, sx);

  const methods = useForm<CreateTeamFormData>({
    defaultValues: {
      name: '',
      tag: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  function displayHelperText(fieldName: string | undefined, fieldHelperText: string | undefined) {
    let helperText = [];
    // @ts-ignore
    helperText.push(fieldHelperText);
    if (errors[fieldName as keyof CreateTeamFormData]) {
      helperText = [];
      if (errors[fieldName as keyof CreateTeamFormData]!.type === 'required') {
        // @ts-ignore
        helperText.push('This field is required');
      }
    }
    return helperText.join(' ');
  }

  return (
    <FormProvider {...methods}>
      <Box
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        component="form"
        sx={styles.root}
        className="CreateTeamForm-root"
      >
        {fields?.map((field, idx) => (
          <Box sx={styles.mailInput} key={idx}>
            <MailIcon />
            <AppInputSimple
              control={control}
              key={field.name}
              inputFieldData={field}
              rules={{
                required: field.required,
              }}
              error={!!errors[field.name as keyof CreateTeamFormData]}
              helperText={displayHelperText(field.name, field.helperText)}
            />
          </Box>

        ))}

        <Button type="submit" variant={formButton?.variant} disabled={true} color={formButton?.color} sx={styles.submitButton}>
          {formButton?.label}
          <ArrowRightIcon />
        </Button>
      </Box>
    </FormProvider>
  );
};

export default CreateEarlyRegister;
