import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputField: {
    maxWidth: 500,
    width: '80%',
    marginBottom: 3,
  },
  checkboxContainer: {
    width: '100%',
    height: '100%',
  },
  submitButton: {
    width: ['100%'],
  },
  accountForm: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 2fr'],
  },
  accountFormFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export { styles as FormStyles };
